import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios"; // Ensure Axios is imported

import { AppBar, Toolbar, Typography, IconButton, Avatar, Button, Modal, Box, Slider } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox'; // Import AddBox icon for the plus button
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Using this as a coin stack icon
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom for navigation
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

function HeaderComponent() {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = useState(false); // State to control modal visibility
    const [credits, setCredits] = useState(0)

    

    const [creditAmount, setCreditAmount] = useState(1); // State to control slider value

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigate = useNavigate(); // Use the useNavigate hook for navigation

    const navigateToArchive = () => navigate('/ArticleCollection');

    const handlePurchase = async (details, data) => {
        
        try {
            const accessToken = await getAccessTokenSilently();

            // Define the endpoint for adding credits
            const addCreditsEndpoint = `${process.env.REACT_APP_BACKEND_URL}/addCredits`;

            // Send a POST request to your Flask endpoint with the accessToken
            const response = await axios.post(addCreditsEndpoint, {
                creditsToAdd: creditAmount // Send the creditAmount as JSON in the body
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`, // Include the authorization header
                    'Content-Type': 'application/json' // Set the content type as JSON
                },
            });

            console.log("Credits added successfully:", response.data);
            // Update the credits state to reflect the new balance
            setCredits(prevCredits => prevCredits + creditAmount);
        } catch (e) {
            console.error("Error adding credits:", e);
        }
        };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "30%",
        bgcolor: '#283747',
        boxShadow: 24,
        p: 4,
        height: "80%"
    };



    useEffect(() => {
        const getUserMetadata = async () => {
            
            try {
                const accessToken = await getAccessTokenSilently();

                // Call your Flask endpoint with the accessToken
                const userEndpoint = `${process.env.REACT_APP_BACKEND_URL}/createUser`; // Adjust with your actual Flask endpoint
                const response = await axios.post(userEndpoint, {}, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                console.log("User creation or update response:", response.data);
            } catch (e) {
                console.error(e);
            }
        };

        if (isAuthenticated) {
            getUserMetadata();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        const getUserMetadata = async () => {
            
            try {
                const accessToken = await getAccessTokenSilently();

                // Call your Flask endpoint with the accessToken
                const userEndpoint = `${process.env.REACT_APP_BACKEND_URL}/getCredits`; // Adjust with your actual Flask endpoint
                const response = await axios.post(userEndpoint, {}, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                setCredits(response.data.credits);
            } catch (e) {
                console.error(e);
            }
        };

        if (isAuthenticated) {
            getUserMetadata();
        }
    }, [isAuthenticated, getAccessTokenSilently]);




    return (
        <>
        <AppBar position="static" color="primary">
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="user">
                    <Avatar alt="User Icon" src={user?.picture} />
                </IconButton>
                <IconButton edge="start" color="inherit" aria-label="archive" onClick={navigateToArchive}>
                        <CollectionsBookmarkIcon />
                    </IconButton>
                <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ marginRight: 2 }}>
                        Credits: {credits}
                    </Typography>
                    <Button color="inherit" onClick={handleOpen}>
                        <AddBoxIcon />
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <MonetizationOnIcon sx={{ mr: 1 }} /> On Demand Pricing
                </Typography>
                <Typography id="modal-modal-subtitle" sx={{ mb: 2 }}>
                    Create full SEO optimized articles.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: 2 }}>
                <h3>Buy {creditAmount} Credits</h3>
                </Typography>
                <Slider
                    aria-label="Credits"
                    defaultValue={1}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={10}
                    max={100}
                    value={creditAmount}
                    onChange={(e, newValue) => setCreditAmount(newValue)}
                />
                <Typography sx={{ mt: 2 }}>
                    <h3>For {creditAmount * 0.5}$</h3>
                </Typography>
                <PayPalScriptProvider options={{ "client-id": "AX_9dze4sbccM-beVQL2I13Yl6lal0qIvLywqYOrfCuuZZ7LrYm7zVkEnSUSzE9Q3bxCVfpXwOLiDxci" }}>
                        <PayPalButtons
                            key={creditAmount}
                            style={{ layout: 'vertical' }}
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            value: creditAmount * 0.5, // Update this based on your pricing
                                        },
                                    }],
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then(details => {
                                    handlePurchase(details, data);
                                });
                            }}
                        />
                    </PayPalScriptProvider>
            </Box>
        </Modal>
    </>
    );
};

export default HeaderComponent;
