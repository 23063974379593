import './App.css';
import UrlInputComponent from './componenets/UrlInputComponent';
import { Auth0Provider } from "@auth0/auth0-react";
import HeaderComponent from './componenets/HeaderComponent';
import { Routes, Route } from 'react-router-dom';
import ArticleCollection from './componenets/ArticleCollection';



function App() {
  return (
    <div>
      <Auth0Provider
        domain="dev-sv4a9m88.us.auth0.com"
        clientId="AZMY6nWevJpUsVSyqZEEnxQBTOnkkYUM"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://backend.blogmuse.io",
        }}
      >

        <HeaderComponent />

        <Routes>
          <Route path="/" element={<UrlInputComponent />} />
          <Route path="/ArticleCollection" element={<ArticleCollection />} />

        </Routes>
      </Auth0Provider>
    </div>
  );
}

export default App;
