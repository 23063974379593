import React, { useEffect, useState } from 'react';
import { TextField, Container, CssBaseline, Chip, Button, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, CircularProgress } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { CardActions } from '@mui/material'; // Import CardActions
import 'react-quill/dist/quill.snow.css'; // Include the quill CSS
import axios from 'axios';
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom for navigation










const fetchImageFromPexels = async (keywords) => {
  // Return a default image if keywords are empty
  if (!keywords || keywords.length === 0) {
    return '/default-image.jpg';
  }

  console.log("Fetching image from Pexels");
  const apiKey = '563492ad6f9170000100000182262b7b341e4afb906168f00ead247c'; // Consider moving this to a secure place
  const query = encodeURIComponent(keywords.join(' '));
  const url = `https://api.pexels.com/v1/search?query=${query}&per_page=1`;





  try {
    const response = await fetch(url, {
      headers: {
        Authorization: apiKey
      }
    });

    if (!response.ok) {
      throw new Error(`Error from Pexels API: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data.photos?.length > 0 ? data.photos[0].src.medium : '/default-image.jpg';
  } catch (error) {
    console.error('Error fetching image:', error);
    return '/default-image.jpg';
  }
};




const UrlInputComponent = () => {
  const [url, setUrl] = useState('https://');
  const [error, setError] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accumulatedStream, setAccumulatedStream] = useState('');

  const [tone, setTone] = useState('Professional');
  const [audience, setAudience] = useState('General');
  const [length, setLength] = useState(900);

  const [imagesUpdated, setImagesUpdated] = useState(false);



  const { getAccessTokenSilently, isAuthenticated, loginWithPopup } = useAuth0();


  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  const navigateToArchive = () => navigate('/ArticleCollection');


  



  const fetchAndAccumulateStream = async (title) => {
    try {
      const token = await getAccessTokenSilently();
  
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/generateBlogPost`,
        title,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };




  const handleToneChange = (event) => {
    setTone(event.target.value);
  };

  const handleAudienceChange = (event) => {
    setAudience(event.target.value);
  };

  const handleLengthChange = (event) => {
    setLength(event.target.value);
  };


    const handleGenerate = (title) => {
      fetchAndAccumulateStream(title); 
      navigateToArchive()
    };
  



  useEffect(() => {
    const updateImagesUpdated = async () => {
      if (blogPosts.length > 0 && !loading && !imagesUpdated) {
        console.log("Updating images for posts");
        const updatedPosts = await Promise.all(blogPosts.map(async (post) => {
          if (!post.imageUrl) {
            const imageUrl = await fetchImageFromPexels(post.keywords);
            return { ...post, imageUrl };
          }
          return post;
        }));

        setBlogPosts(updatedPosts);
        setImagesUpdated(true); // Set the flag to true after updating
      }
    };

    updateImagesUpdated();
  }, [loading, blogPosts, imagesUpdated]);









  // Function to validate the URL
  const isValidUrl = (urlString) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(urlString);
  };

  const handleUrlChange = (event) => {
    const newUrl = event.target.value;
    // Check if the new URL starts with 'https://', if not, prepend 'https://'
    if (!newUrl.startsWith('https://')) {
      setUrl('https://' + newUrl.replace(/^https?:\/\//, ''));
    } else {
      setUrl(newUrl);
    }
    setError(!isValidUrl(newUrl));
  };


  const fetchStream = () => {
    setLoading(true);
    const data = { url: url };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/generateIdeas`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then(response => {
        const reader = response.body.getReader();
        let decoder = new TextDecoder();

        const read = () => {
          reader.read().then(({ done, value }) => {
            if (done) {
              setLoading(false);
              return;
            }
            let chunk = decoder.decode(value, { stream: true });
            console.log(chunk)
            setAccumulatedStream(prevStream => prevStream + chunk);
            read();
          });
        };
        read();
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleItemClick = async (index) => {
    if (!isAuthenticated) {
      await loginWithPopup(); // Trigger login popup
    }
  };
  useEffect(() => {
    if (accumulatedStream) {
      const titleRegex = /Title(?: \d+)?:\s*(.*?)\s*(?=Introduction:)/;
      const postStrings = accumulatedStream.split(titleRegex).slice(1); // Split using the regex

      const posts = [];
      for (let i = 0; i < postStrings.length; i += 2) {
        const title = postStrings[i].trim();
        const rest = postStrings[i + 1];
        if (!title || !rest) continue;

        const [introduction, keywordsString] = rest.split('Keywords:').map(part => part.trim());
        const keywords = keywordsString ? keywordsString.split(',').map(kw => kw.trim()) : [];

        posts.push({ title, introduction, keywords });
      }

      Promise.all(posts.map(async (post) => {
        return { ...post };
      })).then(updatedPosts => {
        setBlogPosts(updatedPosts);
      });
    }
  }, [accumulatedStream]);



  const renderBlogPostCard = (post, index) => {
    return (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Card sx={{ borderRadius: 2, height: '100%' }}>
          <CardActionArea onClick={() => handleItemClick(index)}>
            <CardMedia
              component="img"
              height="140"
              image={post.imageUrl} // Use the fetched image URL
              alt="Blog Post Image"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {post.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {post.introduction.substring(0, 100)}...
              </Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {post.keywords.map((keyword, keyIndex) => (
                  <Chip key={keyIndex} label={keyword} variant="outlined" />
                ))}
              </div>
            </CardContent>
          </CardActionArea>
          {isAuthenticated ?
            <CardActions>
              <Button
                size="small"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: '#1976d2', // Example: blue color
                  color: 'white', // Adjust text color as needed
                  ':hover': { // Correct hover syntax within sx prop
                    backgroundColor: '#115293', // Darker shade for hover state
                  },
                }}
                onClick={() => {handleGenerate(post)}}>
               Create Article for 1 Credit
              </Button>

      
            </CardActions> : null}
        </Card>
      </Grid>
    );
  };

  const renderSpinnerTile = () => {
    return (
      <Grid item xs={12} sm={6} md={3}>
        <CircularProgress style={{ margin: 'auto' }} />
      </Grid>
    );
  };

  return (
   <div>
      <CssBaseline />
      <Container maxWidth="lg" style={{ paddingTop: '20px', paddingBottom: '20px' }}>

        <Grid container justifyContent="center" style={{ marginBottom: '20px' }}>
        <h1>Generate SEO optimized blog articles in BULK</h1>

          <Grid item xs={12} sm={8} md={6}>

            <TextField
              label="Enter your website to automatically generate Content"
              variant="outlined"
              color="primary"
              value={url}
              onChange={handleUrlChange}
              error={error}
              helperText={error ? "Please enter a valid URL." : ""}
              style={{ width: '100%' }} // Adjust width as needed
            />
          </Grid>
        </Grid>



        <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ marginTop: '20px' }}>
          {/* Dropdown for Tone */}
          <Grid item>
            <FormControl variant="outlined" size="small">
              <InputLabel id="tone-label">Tone</InputLabel>
              <Select labelId="tone-label" value={tone} label="Tone" onChange={handleToneChange}>
                <MenuItem value="Professional">Professional</MenuItem>
                <MenuItem value="Casual">Casual</MenuItem>
                <MenuItem value="Informative">Personal</MenuItem>
                <MenuItem value="Informative">Informative</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Dropdown for Audience */}
          <Grid item>
            <FormControl variant="outlined" size="small">
              <InputLabel id="audience-label">Audience</InputLabel>
              <Select labelId="audience-label" value={audience} label="Audience" onChange={handleAudienceChange}>
                <MenuItem value="General">General</MenuItem>
                <MenuItem value="Specialists">Specialists</MenuItem>
                <MenuItem value="Business Professionals">Business Professionals</MenuItem>
                {/* Add more audience types as needed */}
              </Select>
            </FormControl>
          </Grid>

          {/* Dropdown for Length */}
          <Grid item>
            <FormControl variant="outlined" size="small">
              <InputLabel id="length-label">Length</InputLabel>
              <Select labelId="length-label" value={length} label="Length" onChange={handleLengthChange}>
                <MenuItem value={900}>900 words</MenuItem>
                <MenuItem value={1200}>1200 words</MenuItem>
                <MenuItem value={1500}>1500 words</MenuItem>
                {/* Add more length options as needed */}
              </Select>
            </FormControl>
          </Grid>

          {/* Generate Button */}
          <Grid item>
            <Button variant="contained" color="primary" onClick={fetchStream}>
              {loading ? <CircularProgress size={24} /> : 'Generate'}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          {blogPosts.map(renderBlogPostCard)}
          {loading && renderSpinnerTile()} {/* Render spinner if loading */}

        </Grid>
      </Container>
    </div>
  );
};

export default UrlInputComponent;
