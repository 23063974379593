import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Card, CardActionArea, CardContent, Typography, Modal, Box, CircularProgress } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import quill styles
const ArticleCollection = () => {
    const [articles, setArticles] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

  
    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/getAllArticles', {}, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });
                setArticles(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        if (!open) { // Only fetch articles if the modal is not open

            fetchArticles();
        }
        const intervalId = setInterval(fetchArticles, 5000);
        return () => clearInterval(intervalId);
    }, [getAccessTokenSilently, open]);

    const handleOpen = (article) => {
        setSelectedArticle(article);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedArticle(null);
    };

    // Modal style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80vw",
        height: "80vh",
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Grid container spacing={2}>
                {articles.length > 0 ? (
                    articles.map((article, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card>
                                <CardActionArea onClick={() => handleOpen(article)}>
                                    <CardContent>

                                        <Typography gutterBottom variant="h5" component="div">
                                            {article.title}
                                        </Typography>

                                        {!article.html ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                            <CircularProgress />
                                        </div> : null}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <p>No articles found.</p>
                )}
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {selectedArticle && (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {selectedArticle.title}
                            </Typography>
                            <ReactQuill style={{ height: "90%" }} value={selectedArticle.html || ''} readOnly={false} theme="snow" />
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default ArticleCollection;
